import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReCaptcha } from "react-recaptcha-v3";
import Modal from "react-bootstrap/Modal";
import { NOTIFICATIONS, API_KEYS, ARTICLES, PAGE_TITLES, PAGES_TITLE_KEYS } from "../../_constants/constants";
import { AuthenticationService } from "../../_services/auth.service";
import { SharedService } from "../../_services/shared.service";
import { LOCALIZATION } from "../../_constants/localization";
import { Notification } from '../shared/notifications/notifications'
import { setCookie, getCookie } from "../../_services/cookie.service";
import { Helmet } from "react-helmet";

export class AccountDetails extends Component {
  authenticationService = new AuthenticationService();
  sharedService = new SharedService();

  state = {
    client: "",
    planNumber: "",
    isBot: true,
    showPopup: false,
    isFormValid: false,
    globlWebContents: {},
    pageTitle: "",
    groupId: "",
    companyId: ""
  };

  componentDidMount() {
    let { client, clientName, domainGroupId, companyId } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: clientName + PAGE_TITLES.REGISTRATION_IDENTIFICATION, client: client, groupId: domainGroupId, companyId: companyId })

    this.setState({
      planNumber: this.props.location.state || '',
      attemptNo: getCookie('Attempt') === "" ? 0 : parseInt(getCookie('Attempt'))
    }, () => { this.checkRequiredFields(); })

    this.props.setLoading(true);
    this.globalWC_getContent([ARTICLES.REG_ACCOUNT_DETAILS_NOTE, ARTICLES.CERTI_NUM_INFO]);
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  wrongAttempt = () => {
    this.setState({
      attemptNo: this.state.attemptNo + 1
    });
    setCookie('Attempt', this.state.attemptNo);
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items && response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        });
      }
    });
  };

  verifyCallback = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    if (recaptchaToken) {
      let params = {
        token: recaptchaToken
      }
      
      this.props.setLoading(true);
      this.sharedService.verifyCaptcha(params).subscribe(response => {
        this.props.setLoading(false);
        if (response.status === 200) {
          this.setState({
            isBot: response.data.isBot
          });
        }
      });
    } else {
      this.props.setLoading(false);
    }
  };

  checkValidation = () => {
    let errorString = "";
    if (/^\s*$/.test(this.state.planNumber)) {
      // this.setState({ isFormValid: false });
    }

    if (!/^\d+$/.test(this.state.planNumber)) {
      errorString = errorString + NOTIFICATIONS.PLAN_NUM_NUMERIC + "\n";
    }

    if (this.state.planNumber.length < 10) {
      errorString = errorString + NOTIFICATIONS.PLAN_NUM_10_LEN + "\n";
    }

    if (errorString !== "") {
      // this.setState({ isFormValid: false });
      return errorString;
    } else {
      // this.setState({ isFormValid: true });
      return null;
    }
  };

  checkRequiredFields() {
    if (/^\s*$/.test(this.state.planNumber)) {
      this.setState({ isFormValid: false });
      return false;
    }

    this.setState({ isFormValid: true });
    return true;
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value.replace(/\s/g, '') }, () => {
      this.checkRequiredFields();
    });
  };

  handleNext = () => {
    if (!this.state.isBot) {
      if (this.state.isFormValid) {
        let hasValidationError = this.checkValidation();
        if (hasValidationError === null) {
          let params = {
            planNumber: this.state.planNumber.slice(-10), 
            client: this.state.client,
            groupId: this.state.groupId,
            companyId: this.state.companyId
          };

          this.props.setLoading(true);
          this.authenticationService.verifyPolicyNumber(params).subscribe(response => {
            if (response.status === 200) {
              this.props.history.push({
                pathname: "/personal-info",
                planNumber: this.state.planNumber.slice(-10)
              });
            } else {
              this.props.notificationContext.processMessages(response.data.messages);
              this.wrongAttempt();
              // Implementation for IAD-746 - Uncomment before deleting
              // if(this.state.attemptNo > 4) {
              //   this.props.history.push({
              //     pathname: '/'
              //   });
              // }
            }
            this.props.setLoading(false);
          });
        } else if (hasValidationError !== null) {
          this.props.notificationContext.processMessages([{
            type: "ERROR",
            content: [hasValidationError]
          }])
          this.setState({ loading: false }
          );
        }
      }
    } else {
      alert(NOTIFICATIONS.BOT_SUSPECTED);
    }
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleNext();
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.REGISTRATION_IDENTIFICATION] || this.state.pageTitle}</title>
        </Helmet>
        <div className='registerWrapper '>
          <div className='registerHeader'>
            <h1 className='mainHeadingT'>{LOCALIZATION.EN.REGISTRATION_ALL_STEPS}</h1>
            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
          </div>

          <div className='nav nav-pills'>
            <div className='innerBorder'>
              <img alt={""} src={require("../../_assets/images/navBorder.svg").default}></img>
            </div>
            <div className='nav-item'>
              <div className='navbar-link active'>
                <span className='numberText'>1</span>
                <img alt={""} src={require("../../_assets/images/activeCircle.svg").default}></img>
                <br />
                <span className='menuText'>Identification Information</span>
              </div>
            </div>
            <div className='nav-item '>
              <div className='navbar-link'>
                <span className='numberText'>2</span>
                <img alt={""} src={require("../../_assets/images/nonActiveCircle.svg").default}></img>
                <br />
                <span className='menuText'>Personal Information</span>
              </div>
            </div>
            <div className='nav-item'>
              <div className='navbar-link '>
                <span className='numberText'>3</span>
                <img alt={""} src={require("../../_assets/images/nonActiveCircle.svg").default}></img>
                <br />
                <span className='menuText'>Login Credentials</span>
              </div>
            </div>
          </div>
          <div className='usernameWrapper'>
            <div className='lusernameColumn mobileButton'>
              {/* <form action="/" className="was-validation" noValidate> */}
              {/* <h3>{LOCALIZATION.EN.ACCOUNT_DETAILS}</h3> */}
              <div className="mt-3 mb-3 mobileView" ><strong>Step 1: </strong> Identification Information</div>
              <div className='backgroundSetColor'>
                <div className='columnLayout'>
                  <div className='leftCol'>
                    <div className='planNumber mt-3'>
                      <div htmlFor='uname'>Certificate/Plan Number</div>
                      <input value={this.state.planNumber} onKeyDown={this.handleKeyDown} className='form-control' type='text' id='planNumber' onChange={this.handleChange} name='planNumber' required />

                      <Link
                        title={""}
                        to='/#'
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ showPopup: true });
                          this.props.globalContext.triggerGTMDataLayer({
                            event: 'MyAccountPageView',
                            pagePath: '/find-plan-number',
                            pageTitle: `${JSON.parse(localStorage.getItem("initialIds")).clientName} - Where to find your Certificate/Plan Number?`
                          })
                        }}
                        className='findPlanText'>
                        Where to find your Certificate/Plan Number?
                    </Link>
                      {/* <div
                  onClick={() => {
                    this.setState({ showPopup: true });
                  }}
                  className='findPlanText'
                  style={{ marginTop: "10px", color: "#006DA5", fontWeight: "bold" }}>
                  Where to find your Certificate/Plan Number?
                </div> */}
                      <div className='invalid-feedback'>Please fill out this field.</div>
                    </div>
                  </div>

                  <div className='leftCol widthCol desktopView'>
                    <p dangerouslySetInnerHTML={{ __html: this.state.globlWebContents[ARTICLES.REG_ACCOUNT_DETAILS_NOTE] }} id={`globalWC_${ARTICLES.REG_ACCOUNT_DETAILS_NOTE}`} className='noteText'>
                      {/* <strong>Note:</strong> If you have multiple products with us, you only need to input the certificate/plan number for one product to register. Once you register,
                    all of your products will be accessible through your account. */}
                    </p>
                  </div>
                </div>
                <div className='captcha'>
                  <ReCaptcha sitekey={API_KEYS.RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
                  {/* <div>
                    <iframe className='recaptcha-v3-iframe' src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;logging=true&amp;k=6LeuMjIUAAAAAODtAglF13UiJys0y05EjZugej6b&amp;co=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbTo0NDM.&amp;hl=en&amp;v=TbD3vPFlUWKZD-9L4ZxB0HJI&amp;size=invisible&amp;cb=5gq4ych6hfre" width="256" height="60" role="presentation" name="a-9zw0tt3zb1ss" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe>
                  </div> */}
                </div>
              </div>
              <div className='noteMobileText mobileView'>
                <p className='noteText'>
                  <strong>Note:</strong> If you have multiple products with us, you only need to input the certificate/plan number for one product to register. Once you register, all
                  of your products will be accessible through your account.
              </p>
              </div>
              <div className='roundBottomButton'>
                <button id="ma-ra-step1" className={"btn btn-primary agiaBlueButton mt-3 " + (!this.state.isFormValid && "disabled")} type='submit' value='Next' onClick={this.handleNext}>
                  Next: Personal information
              </button>
                <Link id="ma-ra-step1Login" to='/'>Back to login</Link>
              </div>
              {/* <div className='loginText mt-3'>
              Already have an account?
              <Link title={""} to='/'>
                {" "}
                Log in
              </Link>
            </div> */}
              {/* </form> */}
            </div>
          </div>

          <Modal
            backdrop='static'
            className="registarPop"
            show={this.state.showPopup}
            onHide={() => {
              this.setState({ showPopup: false });
            }}>
            <Modal.Header closeButton>
              <Modal.Title>Where to find your Certificate/Plan Number?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <img alt={""} src={require("../../_assets/images/Bill-image.jpg")}></img>
            <img alt={""} src={require("../../_assets/images/Cancer-FF-Image.jpg")}></img>
            <img alt={""} src={require("../../_assets/images/EA-FF-Image.jpg")}></img> */}
              <p dangerouslySetInnerHTML={{ __html: this.state.globlWebContents[ARTICLES.CERTI_NUM_INFO] }}></p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className='btn agiaBlueButton-outline grayButton'
                type='button'
                onClick={() => {
                  this.setState({ showPopup: false });
                  this.props.globalContext.triggerGTMDataLayer({
                    event: 'MyAccountPageView',
                    pagePath: this.props.location.pathname,
                    pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
                  })
                }}>
                Close
            </button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}