import { HttpRequest } from './http/http.service';
import { apiRequest } from '../_constants/url.constants';

export class SearchService {

    http = new HttpRequest();

    searchClient(params) {

        let url = apiRequest.searchClient;

        if (params.search === "account-number") {
            url =  url.replace('company-id/{companyId}/', '')
                .replace('/user-id/{userId}', '')
                .replace('myaccount-core-service', 'agia-appauth-service');
        } else if (params.search === "user-name") {
            url = url.replace('/user-id/{userId}', '')
                .replace('myaccount-core-service', 'agia-appauth-service');
        }

        url = url.replace('{companyId}', params.companyId)
                    .replace('{search}', params.search)
                    .replace('{value}', params.value)
                    .replace('{userId}',params.userId);

        return this.http.get(apiRequest.base + url );
    }
}