import { HttpRequest } from './http/http.service';
import { apiRequest } from '../_constants/url.constants';

export class SharedService {
  http = new HttpRequest();

  getContent(params) {
    return this.http.post(apiRequest.base + apiRequest.getContent, params);
  }

  getContents(params) {
    let hostName = document.location.hostname;
    return this.http.post(apiRequest.base + apiRequest.getContents  + '/' + hostName, params);
  }

  verifyCaptcha(params) {
    return this.http.post(apiRequest.base + apiRequest.verifyCaptcha, params);
  }

  changePassword(params) {
    return this.http.put(apiRequest.base + apiRequest.changePassword, params);
  }

  validationLocation(params) {
    return this.http.get(apiRequest.validationLocation + '/' + params);
  }

  getSecurityQuestions() {
    return this.http.get(apiRequest.base + apiRequest.getSecurityQuestions);
  }

  getStatesList() {
    return this.http.get(apiRequest.base + apiRequest.getStatesList);
  }

  unsubscribe(params) {
    return this.http.put(apiRequest.base + apiRequest.unsubscribe + '/' + params);
  }

  resubscribe(params) {
    return this.http.put(apiRequest.base + apiRequest.resubscribe + '/' + params);
  }

  insproservice() {
    return this.http.get(apiRequest.base + apiRequest.insproservice);
  }

  getPagesTitle(params) {
    return this.http.post(apiRequest.base + apiRequest.getPagesTitle, params);
  }
}