import React, { Component } from "react";
import { NOTIFICATIONS } from "../../_constants/constants";
import { AuthenticationService } from "../../_services/auth.service";
import { LOCALIZATION } from "../../_constants/localization";
import { Link } from "react-router-dom";
import { Notification } from "../shared/notifications/notifications";
import { Helmet } from "react-helmet";
import { PAGE_TITLES, PAGES_TITLE_KEYS } from "../../_constants/constants";

export class PersonalInfo extends Component {
  authenticationService = new AuthenticationService();

  state = {
    planNumber: this.props.location.planNumber,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zipCode: "",

    isVerified: false,
    showPopup: false,
    isFormValid: false,
    pageTitle: "",
  };

  componentDidMount() {
    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({
      pageTitle: clientName + PAGE_TITLES.REGISTRATION_PERSONAL,
    });

    // Check for previously filled form data.
    let savedState = this.props.location.state;
    if (!this.props.location.planNumber && !savedState) {
      this.props.history.push("account-details");
    } else {
      if (savedState) {
        this.setState(
          {
            planNumber: savedState.personalDetails.planNumber,
            firstName: savedState.personalDetails.firstName,
            lastName: savedState.personalDetails.lastName,
            email: savedState.personalDetails.email,
          },
          () => {
            this.checkRequiredFields();
          }
        );
      }
    }
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  checkValidation = () => {
    return new Promise((res, rej) => {
      // Check if plan number is present
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.state.planNumber) {
        this.setState(
          {
            isFormValid: false,
            // errorMessage: NOTIFICATIONS.PLAN_NUMBER_NOT_FOUND
          },
          () => {
            this.props.notificationContext.processMessages([
              {
                type: "FAILURE",
                content: [NOTIFICATIONS.PLAN_NUMBER_NOT_FOUND],
              },
            ]);
            res(false);
          }
        );
      } else if (!re.test(String(this.state.email.trim()).toLowerCase())) {
        this.setState(
          {
            isFormValid: false,
            // errorMessage: NOTIFICATIONS.INVALID_EMAIL_FORMAT
          },
          () => {
            this.props.notificationContext.processMessages([
              {
                type: "FAILURE",
                content: [NOTIFICATIONS.INVALID_EMAIL_FORMAT],
              },
            ]);
            res(false);
          }
        );
      } else {
        this.setState({ isFormValid: true }, () => {
          res(true);
        });
      }
      // Email validation
    });
  };

  checkRequiredFields() {
    return new Promise((res, rej) => {
      if (/^\s*$/.test(this.state.firstName)) {
        this.setState({ isFormValid: false }, () => {
          res(false);
        });
      } else if (/^\s*$/.test(this.state.lastName)) {
        this.setState({ isFormValid: false }, () => {
          res(false);
        });
      } else if (/^\s*$/.test(this.state.email)) {
        this.setState({ isFormValid: false }, () => {
          res(false);
        });
      } else {
        this.setState({ isFormValid: true }, () => {
          res(true);
        });
      }
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.checkRequiredFields();
    });
  };

  handleNext = async () => {
    let requiredFieldOk = await this.checkRequiredFields();
    if (requiredFieldOk) {
      let validationOk = await this.checkValidation();
      if (validationOk) {
        let { client } = JSON.parse(localStorage.getItem("initialIds"));
        let params = {
          client: client,
          planNumber: this.state.planNumber,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          userOS: this.state.userOS,         
        };

        this.props.setLoading(true);
        this.authenticationService
          .verifyPersonalInfo(params)
          .subscribe((response) => {
            localStorage.setItem("clientName", response.data.clientName);
            if (response.status === 200) {
              this.props.history.push({
                pathname: "/sign-in-creds",
                data: {
                  personalDetails: params,
                  accountNumber: response.data.insproAccountNumbers,
                  questions: response.data.questions,
                  personNumber: response.data.personNumber,
                },
              });
            } else {
              this.props.notificationContext.processMessages(
                response.data.messages
              );
            }
            this.props.setLoading(false);
          });
      }
    }
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleNext();
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>
            {this.props.globalContext.state.pagesTitle[
              PAGES_TITLE_KEYS.REGISTRATION_PERSONAL
            ] || this.state.pageTitle}
          </title>
        </Helmet>
        <div className="registerWrapper ">
          <div className="registerHeader">
            <h1 className="mainHeadingT">
              {LOCALIZATION.EN.REGISTRATION_ALL_STEPS}
            </h1>
            {this.props.notificationContext.state.nonInfoNotificaiton.map(
              (notification, index) => (
                <Notification key={index} id={index} message={notification} />
              )
            )}
          </div>
          <div className="nav nav-pills">
            <div className="innerBorder">
              <img
                alt={""}
                src={require("../../_assets/images/navBorder.svg").default}
              ></img>
            </div>
            <div className="nav-item visited">
              <div className="navbar-link">
                <span className="numberText">1</span>
                <img
                  alt={""}
                  src={require("../../_assets/images/visitedCircle.svg").default}
                ></img>
                <br />
                <span className="menuText">Identification Information</span>
              </div>
            </div>
            <div className="nav-item active">
              <div className="navbar-link">
                <span className="numberText">2</span>
                <img
                  alt={""}
                  src={require("../../_assets/images/activeCircle.svg").default}
                ></img>
                <br />
                <span className="menuText">Personal Information</span>
              </div>
            </div>
            <div className="nav-item">
              <div className="navbar-link ">
                <span className="numberText">3</span>
                <img
                  alt={""}
                  src={require("../../_assets/images/nonActiveCircle.svg").default}
                ></img>
                <br />
                <span className="menuText">Login Credentials</span>
              </div>
            </div>
          </div>

          <div className="usernameWrapper">
            <div className="lusernameColumn mobileButton">
              {/* <h3>{LOCALIZATION.EN.PERSONAL_INFO}</h3> */}
              {/* <p>Enter a First name, Last name and Email</p> */}
              <div className="mt-3 mb-3 mobileView">
                <strong>Step 2: </strong> Personal Information
              </div>
              <div className="backgroundSetColor">
                <div className="formLayout">
                  <div className="form-group">
                    <div className="firstname mt-3">
                      <div htmlFor="firstname">First Name</div>
                      <input
                        value={this.state.firstName}
                        className="form-control"
                        type="text"
                        id="firstName"
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChange}
                        name="firstName"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="lastname mt-3">
                      <div htmlFor="lastname">Last Name</div>
                      <input
                        value={this.state.lastName}
                        className="form-control"
                        type="text"
                        id="lastname"
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChange}
                        name="lastName"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="email">
                      <div htmlFor="firstname">Email</div>
                      <input
                        value={this.state.email}
                        className="form-control"
                        type="text"
                        id="email"
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChange}
                        name="email"
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="form-group">
                            <div className="phonenumber">
                                <div htmlFor="phonenumber">
                                    <span className="mandatoryField">&nbsp;&nbsp;&nbsp;</span>
                                    Phone Number
                    </div>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="phonenumber"
                                    onChange={this.handleChange}
                                    name="phone"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="zipcode ">
                                <div htmlFor="zipcode">
                                    <span className="mandatoryField">&nbsp;&nbsp;&nbsp;</span>Zip
                                    Code
                    </div>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="zipcode"
                                    onChange={this.handleChange}
                                    name="zipCode"
                                    required
                                />
                            </div>
                        </div> */}
                </div>
              </div>
              <div className="roundBottomButton">
                <button
                  id="ma-ra-step2"
                  className={
                    "btn btn-primary agiaBlueButton mt-3 " +
                    (!this.state.isFormValid && "disabled")
                  }
                  type="submit"
                  onClick={this.handleNext}
                  value="Next"
                >
                  Next: Login Information
                </button>
                <Link
                  id="ma-ra-step2Login"
                  to={{
                    pathname: `/account-details`,
                    state:
                      this.props.location.planNumber ||
                      (this.props.location.state &&
                        this.props.location.state.personalDetails.planNumber) ||
                      "",
                  }}
                >
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
